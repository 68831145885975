import { useEffect } from 'react';

import { setClientToken } from '../apis/client';

import useToken from './useToken';

export default function useAuthToken() {
  const token = useToken();

  useEffect(() => {
    setClientToken(token);
  }, [token]);
}
