import { useEffect, useState } from 'react';
import i18n from '@src/i18n';
import { SanityLanguagesQuery } from '@src/types/i18n';
import { graphql, useStaticQuery } from 'gatsby';
import { i18n as i18nType } from 'i18next';

export default (): {
  t: Function; // TODO: replace Function with TFunction
  i18n: i18nType;
  isReady: boolean;
} => {
  const [isReady, setIsReady] = useState(false);
  const data = useStaticQuery<SanityLanguagesQuery>(
    graphql`
      query SanityLocaleQuery {
        allSanityLanguages {
          nodes {
            _id
            name
            i18n
          }
        }
      }
    `
  );

  useEffect(() => {
    data.allSanityLanguages.nodes.forEach((locale) => {
      i18n.addResourceBundle(
        locale.name,
        'translation', // default ns
        JSON.parse(locale.i18n),
        true,
        true
      );
    });
    setIsReady(true);
  }, []);

  return { t: i18n.t, i18n, isReady };
};
