import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';

type AvatarImageProps = GatsbyImageProps & {
  sx?: BoxProps['sx'];
};

const styles = {
  root: {
    borderRadius: 1,
    width: 120,
    height: 120,
    overflow: 'hidden',
  },
};

export default function AvatarImage({ sx, ...rest }: AvatarImageProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.root, ...sxProps]}>
      <GatsbyImage objectFit="cover" {...rest} />
    </Box>
  );
}
