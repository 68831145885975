exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-error-tsx": () => import("./../../../src/pages/checkout-error.tsx" /* webpackChunkName: "component---src-pages-checkout-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nft-presale-tsx": () => import("./../../../src/pages/nft-presale.tsx" /* webpackChunkName: "component---src-pages-nft-presale-tsx" */),
  "component---src-pages-nft-tsx": () => import("./../../../src/pages/nft.tsx" /* webpackChunkName: "component---src-pages-nft-tsx" */),
  "component---src-pages-products-compare-tsx": () => import("./../../../src/pages/products/compare.tsx" /* webpackChunkName: "component---src-pages-products-compare-tsx" */),
  "component---src-pages-products-sanity-product-slug-current-tsx": () => import("./../../../src/pages/products/{SanityProduct.slug__current}.tsx" /* webpackChunkName: "component---src-pages-products-sanity-product-slug-current-tsx" */),
  "component---src-pages-profile-collected-tsx": () => import("./../../../src/pages/profile/collected.tsx" /* webpackChunkName: "component---src-pages-profile-collected-tsx" */),
  "component---src-pages-profile-favorites-tsx": () => import("./../../../src/pages/profile/favorites.tsx" /* webpackChunkName: "component---src-pages-profile-favorites-tsx" */)
}

