import axios, { AxiosResponse } from 'axios';

const country2CurrencyMap: Record<string, string> = {
  US: 'USD',
  IN: 'INR',
};
const cloudfrontApi = {
  getViewerHeader: async (): Promise<string> => {
    const res: AxiosResponse<CloudFrontViewerHeaderRes> = await axios.get(
      `${process.env.GATSBY_API_URL}/func/cloudfront/headers`
    );

    const country = res.data.data.headers['cloudfront-viewer-country'];
    return country2CurrencyMap[country] || '';
  },
};

export default cloudfrontApi;
