import { AxiosPromise } from 'axios';

import api from './client';

const marketplaceApi = {
  addPromoCode: (discountCode: string) =>
    api.post('marketplace/v1/promo-code/me', { discountCode }),
  getUserCurrency: (): AxiosPromise<Response<UserCurrencyRes>> =>
    api.get('marketplace/v1/user/currency/me'),
};

export default marketplaceApi;
