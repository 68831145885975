import { useContext } from 'react';
import { toast } from '@avlutils/ui-core';
import apis from '@src/apis';
import useProductStock from '@src/hooks/useProductStock';
import useTranslation from '@src/hooks/useTranslation';

import { TypeProduct } from '../../types/product';

import CartContext from './CartContext';

export default function useCart() {
  const { t } = useTranslation();
  const [value, setValue] = useContext(CartContext);
  const { fetchStock } = useProductStock();

  const closeDiscountDialog = () => {
    setValue((st) => ({ ...st, showDiscountDialog: false }));
  };

  const openDiscountDialog = () => {
    setValue((st) => ({ ...st, showDiscountDialog: true }));
  };

  const applyDiscount = (discountCode: string) => {
    setValue((st) => ({ ...st, discountCode }));
  };

  const cancelDiscount = () => {
    setValue((st) => ({ ...st, discountCode: null }));
  };

  const addCartWithDialog = async (product: TypeProduct) => {
    const stock = await fetchStock(product.sanityId);

    if (!stock) {
      toast.error(t('Oops! {{name}} just sold out.', { name: product.name }));
      return;
    }

    setValue((st) => ({
      ...st,
      activeProduct: product,
      showDiscountDialog: true,
    }));
  };

  const getPaymentLink = async () => {
    const { activeProduct, discountCode } = value;
    if (!activeProduct) return '';

    const stock = await fetchStock(activeProduct.sanityId);

    if (!stock) {
      toast.error(
        t('Oops! {{name}} just sold out.', { name: activeProduct.name })
      );
      return '';
    }

    try {
      const createCheckoutData: ProductCheckoutReq = {
        products: [
          {
            productId: activeProduct.id,
            currency: activeProduct.currency,
            quantity: 1,
          },
        ],
        promoCode: discountCode,
        successUrl: `${process.env.GATSBY_DOMAIN}/profile/collected/`,
        cancelUrl: process.env.GATSBY_DOMAIN as string,
      };
      const response = await apis.product.checkout(createCheckoutData);

      if (response?.data.data.url) return response?.data.data.url;

      toast.error(t('Oops! Something went wrong. Please try again.'));
    } catch (err) {
      toast.error(t('Oops! Something went wrong. Please try again.'));
    }
    return '';
  };

  const checkout = async () => {
    const paymentLink = await getPaymentLink();

    if (paymentLink) {
      window.location.replace(paymentLink);
    }
  };

  return {
    ...value,
    addCartWithDialog,
    openDiscountDialog,
    closeDiscountDialog,
    applyDiscount,
    cancelDiscount,
    checkout,
    getPaymentLink,
  };
}
