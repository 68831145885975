import cloudfront from './cloudfount';
import marketplace from './marketplace';
import product from './product';
import user from './user';

export default {
  marketplace,
  cloudfront,
  product,
  user,
};
