import axios from 'axios';

const client = {
  token: '',
};

const instance = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  withCredentials: true,
});

instance.interceptors.request.use(async (config) => {
  const headers = config.headers || {};
  const { token } = client;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  // eslint-disable-next-line no-param-reassign
  config.headers = headers;

  return config;
});

export const fetcher = (url: string) =>
  instance.get(url).then((response) => response.data);

export const setClientToken = (token: string) => {
  client.token = token;
};

export default instance;
