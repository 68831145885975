import { IGatsbyImageData } from 'gatsby-plugin-image';

interface TypeProductPropertyCategory {
  name: string;
  slug: string;
}
interface TypeProductProperty {
  id: string;
  name: string;
  unicode: string;
  categories: TypeProductPropertyCategory[];
}

export enum ProductRarity {
  SuperRare = 1,
  Rare = 2,
  Uncommon = 3,
  Common = 4,
}

export enum ProductStatus {
  Available = 1,
  Unavailable = -1,
  ComingSoon = 2,
}

export interface TypeProduct {
  id: string;
  name: string;
  slug: string;
  description: string;
  stock: number;
  price: number;
  currency: string;
  ranking: number;
  rarity: number;
  owner: string;
  status: ProductStatus;
  image: IGatsbyImageData | null;
  properties: TypeProductProperty[];
  createdAt: string;
  priceId: string;
  sanityId: string;
}
