import React, { ReactNode } from 'react';
import CartProvider from '@src/widgets/Cart/CartProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

type RootElementProps = { children: ReactNode };
export default function RootElement({ children }: RootElementProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <CartProvider>{children}</CartProvider>
    </QueryClientProvider>
  );
}
