import { AxiosResponse } from 'axios';
import api from '@src/apis/client';
import { useQuery } from '@tanstack/react-query';

import useToken from '../useToken';

export default function useMyPromoCodes() {
  const token = useToken();
  const response = useQuery<AxiosResponse<Response<GetPromoCodeRes[]>>, Error>({
    enabled: !!token,
    queryKey: ['getPromoCodes'],
    queryFn: () => api.get('marketplace/v1/promo-code/me'),
  });

  return response;
}
