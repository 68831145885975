import { ThemeOptions } from '@mui/material/styles';

const baseThemeOptions: ThemeOptions = {
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 20,
          paddingRight: 20,
        },
        maxWidthLg: {
          '@media (min-width: 600px)': {
            paddingLeft: 32,
            paddingRight: 32,
          },
          '@media (min-width: 1200px)': {
            maxWidth: 1180 + 64,
          },
        },
      },
    },
  },
};

export default baseThemeOptions;
