import type { AxiosPromise } from 'axios';

import api from './client';

const product = {
  checkout: (
    params: ProductCheckoutReq
  ): AxiosPromise<Response<PostProductCheckoutRes>> =>
    api.post('marketplace/v1/product/checkout', params),
  addFavorite: (productId: string): AxiosPromise<Response<any>> =>
    api.post(`marketplace/v1/product/${productId}/favorite/me`),
  removeFavorite: (productId: string): AxiosPromise<Response<any>> =>
    api.delete(`marketplace/v1/product/${productId}/favorite/me`),
};

export default product;
