import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getRareIcon, getRareText } from '@src/utils/product.helper';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};
type RarityProps = { sx?: BoxProps['sx']; value: number };

export default function Rarity({ sx, value }: RarityProps) {
  const RareIcon = getRareIcon(value);
  const rareText = getRareText(value);
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.root, ...sxProps]}>
      <RareIcon />
      <Typography variant="body2" fontWeight={500}>
        {rareText}
      </Typography>
    </Box>
  );
}
