import { useAuth0 } from '@auth0/auth0-react';

export default function useAuthProtect() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const checkUserWithRedirect = () => {
    if (!isAuthenticated) {
      loginWithRedirect({
        authorizationParams: { redirect_uri: window.location.origin },
      });
    }

    return isAuthenticated;
  };

  return { isAuthenticated, checkUserWithRedirect };
}
