import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Toaster } from '@avlutils/ui-core';
import RootElement from '@src/components/RootElement';
import useAuthToken from '@src/hooks/useAuthToken';
import { navigate } from 'gatsby';

import 'simplebar-react/dist/simplebar.min.css';
import './src/styles/global.css';

const onRedirectCallback = (appState?: AppState) => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true });
};

export function wrapPageElement({ element }: any) {
  useAuthToken();
  return (
    <RootElement>
      <Toaster />
      {element}
    </RootElement>
  );
}

export function wrapRootElement({ element }: any) {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN as string}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <RootElement>{element}</RootElement>
    </Auth0Provider>
  );
}
