import { createContext, Dispatch, SetStateAction } from 'react';
import { TypeProduct } from '@src/types/product';

export interface CartContextValue {
  activeProduct: TypeProduct | null;
  showDiscountDialog: boolean;
  isPaying: boolean;
  discountCode: string | null;
}

export const initialValue = {
  activeProduct: null,
  showDiscountDialog: false,
  isPaying: false,
  discountCode: null,
};

export type CartContextType = [
  CartContextValue,
  Dispatch<SetStateAction<CartContextValue>>
];

const CartContext = createContext<CartContextType>([initialValue, () => {}]);

export default CartContext;
