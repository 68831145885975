import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { OtherLink as OtherLinkIcon } from '@avlutils/icon-core';
import {
  EmphasizeButton,
  InfoTooltip,
  PopupDialog,
  Typography,
} from '@avlutils/ui-core';
import AvatarImage from '@src/components/AvatarImage';
import Rarity from '@src/components/Rarity';
import useAuthProtect from '@src/hooks/useAuthProtect';
import useCopyToClipboard from '@src/hooks/useCopyToClipboard';
import useTranslation from '@src/hooks/useTranslation';

import CartDiscountForm from './CartDiscountForm';
import useCart from './useCart';

const styles = {
  container: {
    p: 2.5,
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  product: {
    display: 'flex',
    gap: 3,
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px',
  },
  bottomButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 3,
  },
  copyActive: {
    opacity: 1,
    '& .em-button-base': {
      transform: 'translate(4px, 4px)',
      color: 'text.primary',
      bgcolor: 'success.dark',
      boxShadow: 'none',
    },
    '& .em-button-shadow': {
      border: 'none',
      background: 'alpha.successDarkA50',
    },
  },
  tips: { display: { md: 'none' } },
};

type LoadingTargetType = 'checkout' | 'payment-link';

function CartDiscountModal() {
  const { t } = useTranslation();
  const [loadingTarget, setLoadingTarget] = useState<LoadingTargetType | null>(
    null
  );
  const [showTooltip, setShowTooltip] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [copySuccessText, setCopySuccessText] = useState('');
  const {
    discountCode,
    activeProduct,
    showDiscountDialog,
    closeDiscountDialog,
    checkout,
    getPaymentLink,
  } = useCart();
  const { checkUserWithRedirect } = useAuthProtect();
  const linkTimeRef = useRef<NodeJS.Timeout>();
  const copyTimeRef = useRef<NodeJS.Timeout>();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  useEffect(
    () => () => {
      if (linkTimeRef.current) clearTimeout(linkTimeRef.current);
      if (copyTimeRef.current) clearTimeout(copyTimeRef.current);
    },
    []
  );

  useEffect(() => {
    setPaymentLink('');
    if (linkTimeRef.current) clearTimeout(linkTimeRef.current);
  }, [discountCode, activeProduct]);

  if (!activeProduct) return null;

  const goCheckout = async () => {
    if (!checkUserWithRedirect()) return;

    setLoadingTarget('checkout');
    await checkout();
    setLoadingTarget(null);
  };

  const [, copy] = useCopyToClipboard();

  const copyLink = async () => {
    if (paymentLink) {
      copy(paymentLink);
      setCopySuccessText(t('Link Copied!'));
    } else {
      setLoadingTarget('payment-link');

      const link = await getPaymentLink();

      if (link) {
        setCopySuccessText(t('Link Copied!'));
        copy(link);
      }
      setPaymentLink(link);

      setLoadingTarget(null);

      linkTimeRef.current = setTimeout(() => {
        setPaymentLink('');
      }, 30000);
    }

    copyTimeRef.current = setTimeout(() => {
      setCopySuccessText('');
    }, 1000);
  };

  const handleMouseEnter = () => {
    if (mdUp) setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    if (mdUp) setShowTooltip(false);
  };
  return (
    <PopupDialog
      header={t('My Cart')}
      open={showDiscountDialog}
      bottomSheetProps={{ defaultHeight: 590, disableDrag: true }}
      onClose={closeDiscountDialog}
    >
      <Box sx={styles.container}>
        <Box sx={styles.product}>
          <div>
            {!!activeProduct.image && (
              <AvatarImage
                image={activeProduct.image}
                alt={activeProduct.name}
              />
            )}
          </div>
          <Box sx={styles.name}>
            <Typography>{activeProduct.name}</Typography>
            <Rarity value={activeProduct.rarity} />
          </Box>
        </Box>

        <CartDiscountForm product={activeProduct} />
        <Box sx={styles.bottom}>
          <Box sx={styles.bottomButtons}>
            <InfoTooltip
              title={
                copySuccessText ? (
                  <Typography variant="caption">
                    <Trans
                      i18nKey="Link expires in 1 hour"
                      defaults="Link expires in <b>1 hour</b>"
                      components={{ b: <b /> }}
                    />
                  </Typography>
                ) : (
                  t('Copy Payment Link')
                )
              }
              content={
                copySuccessText
                  ? ''
                  : t(
                      'Need help buying this avatar? Simply copy and share the payment link with your parents, friends, or anyone who can lend a hand.'
                    )
              }
              placement="top"
              open={showTooltip || !!copySuccessText}
            >
              <EmphasizeButton
                sx={[!!copySuccessText && styles.copyActive]}
                color="exam"
                variant="outlined"
                onClick={copyLink}
                loading={loadingTarget === 'payment-link'}
                disabled={!activeProduct || !!copySuccessText}
                prefixIcon={<OtherLinkIcon />}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {copySuccessText || t('Payment Link')}
              </EmphasizeButton>
            </InfoTooltip>
            <EmphasizeButton
              color="exam"
              onClick={goCheckout}
              loading={loadingTarget === 'checkout'}
              disabled={!activeProduct}
            >
              {t('Checkout')}
            </EmphasizeButton>
          </Box>
          <Typography sx={styles.tips} variant="caption">
            {t('Need help buying this avatar?')}
            <br />
            {t(
              'Simply copy and share the payment link with your parents, friends, or anyone who can lend a hand.'
            )}
          </Typography>
        </Box>
      </Box>
    </PopupDialog>
  );
}

export default function CartDiscountModalContainer() {
  const { activeProduct } = useCart();

  if (!activeProduct) return null;
  return <CartDiscountModal />;
}
