import { useCallback, useEffect, useState } from 'react';
import sanityClient from '@sanity/client';
import { SanityProductQuantityStock } from '@src/types/sanityProductQuery';

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  useCdn: true,
  apiVersion: '2022-02-01',
});

const stockMap = new Map();

export default function useProductStock(sanityId?: string | null) {
  const [stock, setStock] = useState<number | null>(
    (sanityId && stockMap.get(sanityId)) || null
  );
  const fetchStock = useCallback(async (id: string) => {
    const query = `*[_id == $id]{"sanity_id": _id, quantityStock}`;
    const params = { id };
    let quantityStock = 0;
    try {
      const data = await client.fetch<SanityProductQuantityStock[]>(
        query,
        params
      );

      quantityStock = data[0]?.quantityStock || 0;
      stockMap.set(id, quantityStock);

      setStock(quantityStock);
    } catch (e) {
      setStock(0);
    }

    return quantityStock;
  }, []);

  useEffect(() => {
    if (sanityId && !stockMap.get(sanityId)) fetchStock(sanityId);
  }, [sanityId]);

  return { stock, fetchStock };
}
