import { IGatsbyImageData } from 'gatsby-plugin-image';

import { ProductStatus } from './product';

interface SanityStoreSettings {
  currency: string;
}

interface Asset {
  gatsbyImageData: IGatsbyImageData;
}

interface Image {
  asset: Asset;
}

interface SanityProductPropertyCategory {
  name: string;
  slug: {
    current: string;
  };
}

interface SanityProductProperty {
  _id: string;
  name: string;
  unicode: string;
  categories: SanityProductPropertyCategory[];
}

export enum CurrencyFields {
  USD = 'USD',
  INR = 'INR',
}
export interface CurrencyAttr {
  currency: string;
  price: number;
}
export interface SanityProduct {
  _id: string;
  _createdAt: string;
  name: { current: string };
  slug: { current: string };
  description: string;
  quantityStock: number;
  prices: CurrencyAttr[];
  ranking: number;
  rarity: number;
  owner: string;
  status: ProductStatus;
  image: Image;
  properties: SanityProductProperty[];
}

export interface SanityProductQuery {
  sanityStoreSettings: SanityStoreSettings;
  allSanityProduct: { nodes: SanityProduct[] };
}

export interface SanityProductQuantityStock {
  quantityStock: number;
  sanity_id: string;
}
