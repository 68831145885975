import React, { useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ActionCloseSmall as ActionCloseSmallIcon } from '@avlutils/icon-core';
import {
  Button,
  ResponsiveDropdown,
  TextField,
  Typography,
} from '@avlutils/ui-core';
import apis from '@src/apis';
import useMyPromoCodes from '@src/hooks/queries/useMyPromoCodes';
import useCallAPI from '@src/hooks/useCallAPI';
import useTranslation from '@src/hooks/useTranslation';
import { TypeProduct } from '@src/types/product';

import useCart from './useCart';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  input: {
    display: 'grid',
    gridTemplateColumns: '1fr 64px',
    justifyContent: 'flex-start',
    gap: 3,
  },
  textField: {
    '& .textfield-message': {
      minHeight: 'unset',
    },
  },
  applyButton: {
    mt: '5px',
    minWidth: 'unset',
    px: '12px',
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  priceNumber: {
    fontSize: 24,
  },
  viewDiscountCode: {
    display: { md: 'none' },
  },
  menu: {
    zIndex: 'tooltip',
    '& .popper-content': {
      width: 440,
    },
  },
};

type Option = {
  id: string;
  display: string;
  value: string;
  amountOff: number | null;
  percentOff: number | null;
};

type CartDiscountFieldProps = {
  onApply: (value: Option) => void;
  onApplyNewCode: () => void;
  options: Option[];
};

function CartDiscountField({
  onApply,
  options,
  onApplyNewCode,
}: CartDiscountFieldProps) {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [searchDiscount, setSearchDiscount] = useState('');

  const [selectedDiscount, setSelectedDiscount] = useState<Option | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { call } = useCallAPI();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleDropdownChange = (option: Option) => {
    const currentItem = options.find((o) => o.value === option.value);
    if (currentItem) {
      setOpenDropdown(false);
      setSearchDiscount('');
      setSelectedDiscount({
        ...option,
        id: currentItem.id,
        amountOff: currentItem.amountOff,
        percentOff: currentItem.percentOff,
      });
    }
  };

  const handleApply = async () => {
    const validDiscountCode = searchDiscount?.trim()?.toLowerCase();
    if (validDiscountCode) {
      const currentItem = options.find(
        (o) => o.display.toLowerCase() === validDiscountCode
      );
      if (currentItem) {
        onApply(currentItem);
        return;
      }
      setApplyLoading(true);
      const [res, err] = await call<Response<GetPromoCodeRes>>(
        apis.marketplace.addPromoCode(validDiscountCode)
      );

      if (res) {
        onApplyNewCode();
        setErrorMessage('');
        onApply({
          display: res.data.discountCode,
          value: res.data.code,
          id: res.data.id,
          amountOff: res.data.amountOff,
          percentOff: res.data.percentOff,
        });
      } else if (err.statusCode === 409) {
        setErrorMessage('Nice try! Use someone else’s, not yours');
      } else {
        setErrorMessage(t('Discount code not found.'));
      }

      setApplyLoading(false);
    } else if (selectedDiscount) {
      onApply(selectedDiscount);
    }
  };

  const filterOptions =
    mdUp && searchDiscount
      ? options.filter(
          (o) =>
            o.display.toLowerCase().indexOf(searchDiscount.toLowerCase()) > -1
        )
      : options;
  const handleSearchChange = (value: string) => {
    setErrorMessage('');
    setSelectedDiscount(null);
    setSearchDiscount(value);
    if (mdUp) {
      setOpenDropdown(true);
    }
  };

  const discountValue = searchDiscount || selectedDiscount?.display || '';
  return (
    <>
      <Box sx={styles.input}>
        <TextField
          ref={inputRef}
          label={t('Discount Code')}
          sx={styles.textField}
          placeholder={t('Enter discount code')}
          value={discountValue}
          onChange={(ev) => handleSearchChange(ev.target.value)}
          error={!!errorMessage}
          helperText={errorMessage}
        />
        <div>
          <Button
            sx={styles.applyButton}
            onClick={handleApply}
            loading={applyLoading}
            disabled={!discountValue}
          >
            {t('Apply')}
          </Button>
        </div>
      </Box>
      <Typography
        sx={styles.viewDiscountCode}
        variant="body2"
        fontWeight={700}
        onClick={() => setOpenDropdown(true)}
      >
        {t('View my discount code')}
      </Typography>
      <ResponsiveDropdown
        open={(!mdUp || (mdUp && filterOptions.length > 0)) && openDropdown}
        onClick={handleDropdownChange}
        options={filterOptions}
        onClose={() => setOpenDropdown(false)}
        menuDropdownProps={{
          anchorEl: inputRef.current,
          popperProps: { sx: styles.menu },
        }}
        bottomDropdownProps={{ label: t('My Discount Code') }}
      />
    </>
  );
}

type CartDiscountFormProps = {
  product: TypeProduct;
};

const getDiscount = (price: number, applyDiscount: Option) => {
  if (applyDiscount.percentOff) {
    return price * (applyDiscount.percentOff / 100);
  }

  return applyDiscount.amountOff || 0;
};

export default function CartDiscountForm({ product }: CartDiscountFormProps) {
  const { t } = useTranslation();
  const { discountCode, applyDiscount, cancelDiscount } = useCart();

  const { data, refetch } = useMyPromoCodes();

  const options: Option[] = useMemo(() => {
    if (!data || !data.data.data) return [];

    return data.data.data.map((d) => ({
      display: d.discountCode,
      value: d.code,
      id: d.id,
      amountOff: d.amountOff,
      percentOff: d.percentOff,
    }));
  }, [data]);

  const discountItem = options.find((o) => o.value === discountCode);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: product.currency,
  });

  const discount = discountItem ? getDiscount(product.price, discountItem) : 0;

  const handleApplyDiscount = (option: Option) => {
    applyDiscount(option.value as string);
  };

  const handleCancelDiscount = () => {
    cancelDiscount();
  };
  return (
    <Box sx={styles.root}>
      {discountItem ? (
        <Box>
          <Chip
            variant="outlined"
            label={discountItem.display}
            deleteIcon={
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transitionDuration: '0.2s',
                  '&.MuiChip-deleteIcon:not(:disabled):hover': {
                    bgcolor: 'text.primary',
                    color: 'background.darker',
                  },
                  '&.MuiChip-deleteIcon:not(:disabled):active': {
                    bgcolor: 'grey.400',
                  },
                }}
              >
                <ActionCloseSmallIcon width="18" height="18" />
              </Box>
            }
            onDelete={handleCancelDiscount}
          />
        </Box>
      ) : (
        <CartDiscountField
          onApply={handleApplyDiscount}
          options={options}
          onApplyNewCode={refetch}
        />
      )}

      <Box sx={styles.total}>
        <Typography variant="subtitle2" fontWeight={400}>
          {t('Subtotal')}
        </Typography>
        <Typography type="number">{formatter.format(product.price)}</Typography>
      </Box>
      {!!discountItem && (
        <Box sx={styles.total}>
          <Typography variant="subtitle2" fontWeight={400}>
            {t('Discount')}
          </Typography>
          <Typography type="number">
            -
            {discountItem.percentOff
              ? `${discountItem.percentOff}%`
              : formatter.format(discountItem.amountOff || 0)}
          </Typography>
        </Box>
      )}
      <Box sx={styles.total}>
        <Typography variant="subtitle2" fontWeight={400}>
          {t('Total due')}
        </Typography>
        <Typography sx={styles.priceNumber} type="number">
          {formatter.format(product.price - discount)}
        </Typography>
      </Box>
    </Box>
  );
}
