/* eslint-disable no-underscore-dangle */
import {
  NFTCommon as NFTCommonIcon,
  NFTRare as NFTRareIcon,
  NFTSuperRare as NFTSuperRareIcon,
  NFTUncommon as NFTUncommonIcon,
} from '@avlutils/icon-core';
import { ProductRarity, TypeProduct } from '@src/types/product';
import {
  CurrencyAttr,
  CurrencyFields,
  SanityProduct,
} from '@src/types/sanityProductQuery';

export const getRareText = (value: ProductRarity) => {
  if (value === ProductRarity.SuperRare) return 'Super Rare';
  if (value === ProductRarity.Rare) return 'Rare';
  if (value === ProductRarity.Uncommon) return 'Uncommon';
  return 'Common';
};

export const getRareIcon = (value: ProductRarity) => {
  if (value === ProductRarity.SuperRare) return NFTSuperRareIcon;
  if (value === ProductRarity.Rare) return NFTRareIcon;
  if (value === ProductRarity.Uncommon) return NFTUncommonIcon;
  return NFTCommonIcon;
};

export const getTypeProduct = (
  product: SanityProduct,
  currency: string = CurrencyFields.USD
): TypeProduct => {
  const hasImage =
    product.image?.asset != null &&
    product.image?.asset.gatsbyImageData != null;
  const productImage = hasImage ? product.image?.asset.gatsbyImageData : null;

  // Check to make sure there is a description as well and provide a fallback
  const hasDescription = product.description != null;
  const productDescription = hasDescription ? product.description : '';
  const productProperties = product.properties
    .filter((p) => p !== null)
    .map((property) => ({
      id: property._id,
      name: property.name,
      unicode: property.unicode,
      categories: property.categories.map((category) => ({
        name: category.name,
        slug: category.slug.current,
      })),
    }));

  const priceMap = product.prices.reduce((acc, cur) => {
    acc[cur.currency] = cur;
    return acc;
  }, {} as Record<string, CurrencyAttr>);

  const productPrice = priceMap[currency]?.price;

  const productFormatted = {
    id: product._id,
    name: product.name.current,
    slug: product.slug.current,
    description: productDescription,
    createdAt: product._createdAt,
    price: productPrice,
    currency,
    ranking: product.ranking,
    rarity: product.rarity,
    owner: product.owner,
    status: product.status,
    properties: productProperties,
    image: productImage,
    stock: product.quantityStock ?? 0,
    priceId: product._id,
    sanityId: product._id,
  };

  return productFormatted;
};

export const currency2LocaleMap: Record<string, string> = {
  USD: 'en-US',
  INR: 'gu-IN',
};
