import React, { ReactNode, useState } from 'react';

import CartContext, { CartContextValue, initialValue } from './CartContext';
import CartDiscountModal from './CartDiscountModal';

type CartProviderProps = {
  children: ReactNode;
};
export default function CartProvider({ children }: CartProviderProps) {
  const providerValue = useState<CartContextValue>(initialValue);

  return (
    <CartContext.Provider value={providerValue}>
      {children}
      <CartDiscountModal />
    </CartContext.Provider>
  );
}
