import { useCallback } from 'react';
import { ToastOptions } from 'react-hot-toast';
import nextId from 'react-id-generator';
import { AxiosPromise, AxiosResponse } from 'axios';
import { BaseSnackbarProps, toast } from '@avlutils/ui-core';
import useTranslation from '@src/hooks/useTranslation';

type APIType =
  | AxiosPromise
  | AxiosPromise[]
  | (() => AxiosPromise)
  | (() => AxiosPromise)[];

type Options<V> = Partial<
  BaseSnackbarProps & {
    variant: V;
    anchorEl?: Element | undefined;
    disableClose?: boolean | undefined;
    toastOptions?: ToastOptions;
  }
>;
export interface CallWithToastParams {
  id?: string;
  confirmMsg?: string;
  successMsg?: string;
  errorMsg?: string;
  loadingMsg?: string;
  confirmText?: string;
  showAPIError?: boolean;
  showLoading?: boolean;
  toastOptions?: ToastOptions;
  anchorEl?: Element | undefined;
  confirmOptions?: Options<'base'> & { confirmText?: string };
  successOptions?: Options<'base'>;
  errorOptions?: Options<'base'>;
  loadingOptions?: Options<'loading'>;
  onSuccess?: (res: any) => void;
  onError?: (res: any) => void;
}
export default function useCallAPI() {
  const { t } = useTranslation();
  const call = useCallback(
    async <T, U = any>(api: APIType): Promise<[T | null, any | U]> => {
      try {
        if (Array.isArray(api)) {
          const res: any = await Promise.all(
            api.map((a) => (a instanceof Promise ? a : a()))
          );
          return [res.map((r: AxiosResponse<Response<any>>) => r.data), null];
        }

        const res: AxiosResponse<T> = await (api instanceof Promise
          ? api
          : api());

        if (res.data) {
          return [res.data, null];
        }

        return [null, res.data];
      } catch (err: any) {
        if (err?.response) {
          return [null, err.response.data];
        }
        return [null, err];
      }
    },
    []
  );

  const callWithToast = useCallback(
    async <T, U>(
      api: APIType,
      {
        id = '',
        confirmMsg = '',
        confirmText = t('Yes'),
        successMsg = '',
        errorMsg = '',
        loadingMsg = t('Loading...'),
        showAPIError = true,
        showLoading = true,
        anchorEl,
        toastOptions = {},
        confirmOptions = { toastOptions: { duration: 10000 } },
        successOptions = { toastOptions: { duration: 3000 } },
        errorOptions = { toastOptions: { duration: 5000 } },
        loadingOptions = { disableClose: true, toastOptions: {} },
        onSuccess,
        onError,
      }: CallWithToastParams = {}
    ): Promise<[T | null, any, string | null]> => {
      const toastId = id || nextId();

      const baseOptions = {
        ...toastOptions,
        id: toastId,
      };

      if (confirmMsg) {
        const checked = await toast.confirm(
          confirmMsg,
          {
            anchorEl,
            confirmText,
          },
          { ...baseOptions, ...(confirmOptions.toastOptions || {}) }
        );
        if (!checked) {
          return [null, null, toastId];
        }
      }

      if (showLoading) {
        toast.loading(
          loadingMsg,
          { anchorEl },
          { ...baseOptions, ...(loadingOptions.toastOptions || {}) }
        );
      }

      const [success, error] = await call<T, U>(api);
      if (showLoading) {
        toast.dismiss(toastId);
      }
      if (success && successMsg) {
        toast.success(
          successMsg,
          { anchorEl },
          {
            ...baseOptions,
            ...(successOptions.toastOptions || {}),
          }
        );
        onSuccess?.(success);
      }

      if (error) {
        if (errorMsg) {
          toast.error(
            errorMsg,
            { anchorEl },
            {
              ...baseOptions,
              ...(errorOptions.toastOptions || {}),
            }
          );
        } else if (showAPIError) {
          // 後端目前有傳三種格式
          const errorMassage =
            error.message ||
            error.response?.message ||
            error.response?.data?.message;

          toast.error(
            typeof errorMassage === 'string'
              ? errorMassage
              : 'Internal server error',
            { anchorEl },
            {
              ...baseOptions,
              ...(errorOptions.toastOptions || {}),
            }
          );
        }
        onError?.(error);
      }

      return [success, error, toastId];
    },
    []
  );

  return { call, callWithToast };
}
